<template>
  <div class="alarm-item-box">
    <div class="title-box">
      <div class="left">
        <i class="iconfont iconsanwei"></i>
        <h3>{{data.dataName}}</h3>
        <!-- <span>({{data.unit}})</span> -->
      </div>
      <div class="right">
        <i class="iconfont iconshijian"></i>
        <h4>{{data.warnTime}}</h4>
      </div>
    </div>
    <div class="list-box">
      <ul>
        <li>
          <label>设备名称：</label>
          <span>{{data.deviceName+'（ '+data.deviceNo+'）'}}</span>
        </li>
        <li>
          <label>事件信息：</label>
          <span>{{data.description}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AlarmItem",
    props: {
      data: {
        type: Object,
        default () {
          return {}
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/mixin";
  .alarm-item-box{
    @include wh(100%, 7rem);
    background-color: white;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    padding-left: 0.5rem;
    .title-box{
      @include wh(100%, 2.5rem);
      border-bottom: 1px solid #f1f1f1;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 0.5rem;
      .left{
        display: flex;
        align-items: center;
        h3{
          font-size: 0.8rem;
          color: #333333;
          margin-right: 0.2rem;
        }
        span{
          font-size: 0.6rem;
          color: #999999;
        }
        i{
          font-size: 1rem;
          color: #4A8CFF;
          margin-right: 0.5rem;
          margin-top: -0.4rem;
        }
      }
      .right{
        display: flex;
        align-items: center;
        h4{
          font-size: 0.6rem;
          color: #333333;
        }
        i{
          font-size: 1rem;
          color: #CCCCCC;
          margin-right: 0.5rem;
          margin-top: -0.4rem;
        }
      }
    }
    .list-box{
      @include wh(100%, 4.5rem);
      ul{
        @include wh(100%, 100%);
        box-sizing: border-box;
        padding: 0.2rem 0;
        li{
          @include wh(100%, 50%);
          display: flex;
          align-items: center;
          label{
            font-size: 0.7rem;
            color: #333333;
          }
          span{
            width: 0;
            flex: 1;
            font-size: 0.7rem;
            color: #333333;
            i{
              color: #FF4949;
            }
            .text{
              color: #999999;
            }
          }
        }
      }
    }
  }
</style>
