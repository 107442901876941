<template>
  <div class="bar-panel-wrapper">
    <div class="title-wrapper">
      <h3>7天告警数</h3>
      <span>（全部设备）</span>
    </div>
    <div class="bar-wrapper" ref="barEcharts"></div>
  </div>
</template>

<script>
  let echarts = require('echarts/lib/echarts');
  require('echarts/lib/chart/bar');
  export default {
    name: "BarPanel",
    props: {
      barData: {
        type: Object,
        default () {
          return {
            xData: [],
            yData: []
          }
        }
      }
    },
    mounted () {
      this.drawBar()
    },
    methods: {
      drawBar () {
        const { xData, yData } = this.barData;
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(this.$refs.barEcharts);

        // 指定图表的配置项和数据
        var option = {
          grid: {
            top: 30,
            left: 40,
            right: 20,
            bottom: 30
          },
          xAxis: {
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: '#666666',
              fontSize: 12
            },
            data: xData
          },
          yAxis: {
            name: '次数',
            nameTextStyle: {
              color: '#999'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: '#666666',
              fontSize: 12
            },
            splitLine: {
              lineStyle: {
                color: '#f1f1f1'
              }
            }
          },
          series: [{
            name: '销量',
            type: 'bar',
            barMaxWidth: 15,
            symbolSize: 0,
            itemStyle: {
              color: '#FE9A00'
            },
            data: yData
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/mixin";
  .bar-panel-wrapper{
    @include wh(100%, 10rem);
    background-color: white;
    margin-bottom: 0.5rem;
    .title-wrapper{
      @include wh(100%, 2.5rem);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0 1rem;
      h3{
        font-size: 0.85rem;
        color: #333333;
        font-weight: bold;
      }
      span{
        font-size: 0.7rem;
        color: #999999;
      }
    }
    .bar-wrapper{
      @include wh(100%, 7.5rem);
    }
  }
</style>
