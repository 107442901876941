import axios from '@/config/axios'

// 近7天告警数统计
export const getAlarmStatistics = () => axios({
  method: 'get',
  url: `/mobile/warn/near7DaysWarn`
})

// 告警事件查询
export const getAlarms = (params) => axios({
  method: 'get',
  url: `/mobile/warn/nearWarnDataList`,
  params
})
