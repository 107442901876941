<template>
  <div class="alarm-event-wrapper">
    <div class="has-data-wrapper" v-if="hasData">
      <div class="bar-panel-wrapper">
        <BarPanel :barData="barData" />
      </div>
      <div class="device-main-wrapper" :class="deviceScroll ? 'device-scroll-active' : ''">
        <div class="device-option">
          <div class="area-box single-row" @click="handleSelectArea">
            {{ groupName ? groupName : '分组' }}
          </div>
          <div class="select-box">
            <van-dropdown-menu>
              <van-dropdown-item v-model="params.deviceType" :options="option2" @change="handleChangeOption" />
              <!-- <van-dropdown-item
                v-model="params.liveStatus"
                :options="option3"
                @change="handleChangeOption"
              /> -->
              <van-dropdown-item v-model="params.isFollow" :options="option4" @change="handleChangeOption" />
            </van-dropdown-menu>
          </div>
        </div>
        <div class="search-list-wrapper" @scroll="searchScroll" ref="searchBox">
          <van-list v-model="loading" :finished="finished" @load="onLoad" finished-text="没有更多了">
            <AlarmItem v-for="item in list" :key="item.alarm_time" :data="item" />
          </van-list>
        </div>
      </div>
    </div>
    <NoData v-if="!hasData" type="AlarmEvent" />
    <!--分组-->
    <Area :showPopup="showPopup" @handleConfirmPicker="handleConfirmPicker" @onCancel="onCancel" />
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { DropdownMenu, DropdownItem, List, Cell } from 'vant'
import 'vant/lib/dropdown-menu/style'
import 'vant/lib/dropdown-item/style'
import 'vant/lib/list/style'
Vue.use(List).use(Cell).use(DropdownMenu).use(DropdownItem)

import BarPanel from './components/BarPanel'
import AlarmItem from './components/AlarmItem'
import NoData from '@/components/NoData'
import Area from '@/components/Area'
import { getAlarmStatistics, getAlarms } from '@/api/alarmEvent'
import { getEnums, deviceDictType } from '@/api/deviceMonitor'
export default {
  name: 'index',
  components: {
    BarPanel,
    AlarmItem,
    NoData,
    Area,
  },
  data() {
    return {
      option2: [{ text: '全部类型', value: '' }],
      option3: [{ text: '全部状态', value: '' }],
      option4: [
        { text: '关注', value: '' },
        { text: '我关注的', value: 1 },
        { text: '我不关注', value: 0 },
      ],
      list: [],
      barData: {
        xData: [],
        yData: [],
      },
      loading: false,
      finished: false,
      deviceScroll: false,
      params: {
        page: 0,
        size: 10,
        deviceType: '',
        // liveStatus: '',
        isFollow: '',
      },
      groupName: '',
      hasData: false,
      showPopup: false,
    }
  },
  mounted() {
    this.getAlarmStatistics()
    this.getDeviceDictType()
  },
  methods: {
    /**
     * 监听列表滚动
     */
    searchScroll() {
      // let top = this.$refs.searchBox.scrollTop
      // if (top === 0) {
      //   this.deviceScroll = false
      // }
      // if (top > 0) {
      //   this.deviceScroll = true
      // }
    },
    /**
     * 近7天告警数统计
     */
    getAlarmStatistics() {
      getAlarmStatistics().then((res) => {
        this.barData = {
          xData: [],
          yData: [],
        }
        if (res.length > 0) {
          this.hasData = true
          res.forEach((item) => {
            this.barData.xData.push(item.warnDate)
            this.barData.yData.push(item.total)
          })
        } else {
          this.hasData = false
        }
      })
    },
    /**
     * 告警事件查询
     */
    getAlarms() {
      // this.params.locationId = this.selectAreaDataValue
      // this.params.alarmDate = this.alarmDate
      // this.value1 ? (this.params.type = this.value1) : (this.params.type = '')
      // this.value3
      //   ? (this.params.isFollow = this.value3 - 1)
      //   : (this.params.isFollow = '')

      getAlarms(this.params).then((res) => {
        const { content, totalElements } = res
        if (this.params.page === 0) {
          this.list = []
        }
        this.params.page = this.params.page + 1
        if (content.length === 0) {
          this.finished = true
          return
        }

        content.forEach((item) => {
          // item.alarm_time = moment(item.alarm_time).format(
          //   'YYYY-MM-DD HH:mm:ss'
          // )
          this.list.push(item)
        })
        this.loading = false
        if (this.list.length >= totalElements) {
          // this.finished = true
          // return

            this.finished = true
            this.loading = true
        }else {
            this.params.page = this.params.page + 1
          }
      })
    },
    /**
     * 区域选择
     */
    handleSelectArea() {
      this.showPopup = true
    },
    onCancel() {
      this.showPopup = false
    },
    /**
     * 区域确定选择
     */
    handleConfirmPicker(val) {
      // this.selectAreaDataName = val
      // this.selectAreaDataValue = id

      // this.params.page = 0
      // this.list = []
      // this.getAlarms()
      // this.showPopup = false

      this.params.groupId = val.id
      this.groupName = val.text
      this.params.page = 0
      this.showPopup = false
      this.list = []
      this.getAlarms()
    },
    /**
     * 改变选择条件
     */
    handleChangeOption() {
      this.list = []
      this.params.page = 0
      this.getAlarms()
    },
    /**
     * 选择日期
     */
    handleSelectDate() {
      this.showDate = true
    },
    /**
     * 获取设备类型
     */
    getDeviceTypeRequest() {
      // getEnums({
      //   names: '设备类型',
      // }).then((res) => {
      //   this.option1 = [{ text: '类型', value: 0 }]
      //   res['设备类型'].forEach((item) => {
      //     this.option1.push({
      //       text: item.label,
      //       value: item.value,
      //     })
      //   })
      // })
    },

    getDeviceDictType() {
      /**
       * 获取设备类型
       */
      deviceDictType(1).then((res) => {
        this.option2 = [{ text: '全部类型', value: '' }]
        res.forEach((item) => {
          this.option2.push({
            text: item.label,
            value: item.status,
          })
        })
      })
      /**
       * 获取设备状态
       */
      deviceDictType(5).then((res) => {
        this.option3 = [{ text: '全部状态', value: '' }]
        res.forEach((item) => {
          this.option3.push({
            text: item.label,
            value: item.id,
          })
        })
      })
    },
    /**
     * 确定选择日期
     */
    handleConfirmDatePopup(val) {
      this.alarmDate = moment(val).format('YYYY-MM-DD')
      this.params.page = 0
      this.list = []
      this.getAlarms()
      this.showDate = false
    },
    /**
     * 取消选择日期
     */
    handleCancelDatePopup() {
      this.showDate = false
    },
    onLoad() {
      setTimeout(() => {
        this.getAlarms()
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.alarm-event-wrapper {
  @include wh(100%, 100%);
  background-color: #f1f1f1;
  .has-data-wrapper {
    @include wh(100%, 100%);
    display: flex;
    flex-direction: column;
    .bar-panel-wrapper {
      width: 100%;
      height: 10rem;
    }
  }
  .device-main-wrapper {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding-top: 50px;
    position: relative;
    overflow-y: auto;
    &.device-scroll-active {
      position: fixed;
      top: 3rem;
      left: 0;
    }
    .device-option {
      @include wh(100%, 50px);
      display: flex;
      justify-content: space-between;
      background-color: white;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      .area-box {
        width: 4rem;
        height: 100%;
        font-size: 15px;
        color: #999;
        text-align: center;
        line-height: 50px;
        position: relative;
        box-sizing: border-box;
        padding-left: 0.1rem;
        &::after {
          position: absolute;
          top: 50%;
          margin-left: 5px;
          margin-top: -5px;
          border: 3px solid;
          border-color: transparent transparent currentColor currentColor;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          opacity: 0.8;
          content: '';
        }
      }
      .select-box {
        flex: 1;
        /deep/.van-ellipsis {
          color: #999;
        }
        /deep/.van-dropdown-menu__title::after {
          color: #999;
        }
      }
      .follow-box {
        width: 5rem;
        height: 100%;
        font-size: 15px;
        color: #999;
        text-align: center;
        line-height: 50px;
      }
    }
    .search-list-wrapper {
      width: 100%;
      height: 100%;
      background-color: #f1f1f1;
      box-sizing: border-box;
      padding: 0 0 3rem 0;
      overflow-y: auto;
      /deep/.van-list__finished-text {
        font-size: 0.7rem;
      }
    }
  }
}
</style>
